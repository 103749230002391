import PropTypes from 'prop-types';
import { useEffect, useState, Fragment } from 'react';
import 'moment/locale/es';

// material-ui
import { Chip, Divider, Grid, List, ListItemButton, ListItemAvatar, ListItemText, Typography } from '@mui/material';
import SyncIcon from '@mui/icons-material/Sync';
// project imports
import UserAvatar from './UserAvatar';

import { useDispatch, useSelector } from 'store';
import { getUsers } from 'store/slices/chat';
import { formatPhone } from 'utils/format';
import moment from 'moment';

// ==============================|| CHAT USER LIST ||============================== //

const UserList = ({ setUser, users, hasMoreChats, loadMoreChats}) => {
    const dispatch = useDispatch();
    const [data, setData] = useState([]);
    /*const { users } = useSelector((state) => state.chat);

    useEffect(() => {
        dispatch(getUsers());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);*/

    useEffect(() => {
        setData(users);
    }, [users]);
    if (data.length === 0) {
        return (
            <List component="nav">
                <ListItemButton>
                    <ListItemText primary="No hay conversaciones" />
                </ListItemButton>
            </List>
        );
    }
    return (
        <List component="nav">
            {data.sort((a, b) => new Date(a.lastMessageDate) - new Date(b.lastMessageDate)).map((user) => (
                <Fragment key={user.id}>
                    <ListItemButton
                        onClick={() => {
                            setUser(user);
                        }}
                    >
                        <ListItemAvatar>
                            <UserAvatar user={user} />
                        </ListItemAvatar>
                        <ListItemText
                            primary={
                                <Grid container alignItems="center" spacing={1} component="span">
                                    <Grid item xs zeroMinWidth component="span">
                                        <Typography
                                            variant="h5"
                                            color="inherit"
                                            component="span"
                                            sx={{
                                                overflow: 'hidden',
                                                textOverflow: 'ellipsis',
                                                whiteSpace: 'nowrap',
                                                display: 'block'
                                            }}
                                        >
                                            {/* length == 3 porque si no tiene nombre fullName trae: "- -"*/}
                                            {user.fullName && user.fullName.length > 3 ? user.fullName : formatPhone(user.name)}
                                            <br />
                                            <Typography component="span" variant="subtitle2">
                                                {user.lastMessage}
                                            </Typography>
                                        </Typography>
                                    </Grid>
                                     <Grid item component="span">
                                        <Typography align="right" variant="caption" fontSize={10}>
                                            {user.lastMessageDate ? (
                                                moment(user.lastMessageDate).isSame(moment(), 'day') 
                                                ? moment(user.lastMessageDate).format('h:mm A') 
                                                : (moment(user.lastMessageDate).isSame(moment(), 'week')
                                                ? moment(user.lastMessageDate).format('dddd')
                                                : moment(user.lastMessageDate).format('YYYY-MM-DD'))
                                            ) : ''}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            }
                            secondary={
                                <Grid container alignItems="center" spacing={1} component="span">
                                    <Grid item xs zeroMinWidth component="span">
                                        <Typography
                                            variant="caption"
                                            component="span"
                                            sx={{
                                                overflow: 'hidden',
                                                textOverflow: 'ellipsis',
                                                whiteSpace: 'nowrap',
                                                display: 'block'
                                            }}
                                        >
                                            {user.lastMessage}
                                        </Typography>
                                    </Grid>
                                    <Grid item component="span">
                                        {user.unReadChatCount !== 0 && (
                                            <Chip
                                                label={user.unReadChatCount}
                                                component="span"
                                                color="secondary"
                                                sx={{
                                                    height: 20,
                                                    '& .MuiChip-label': {
                                                        px: 0.5
                                                    }
                                                }}
                                            />
                                        )}
                                    </Grid>
                                </Grid>
                            }
                        />
                    </ListItemButton>
                    <Divider />
                </Fragment>
            ))}
            {hasMoreChats ? (
                <List component="nav">
                    <ListItemButton onClick={loadMoreChats}>
                        <ListItemText primary="Cargar mas conversaciones" /><SyncIcon />
                    </ListItemButton>
                </List>
            ) : false}
        </List>
    );
};

UserList.propTypes = {
    setUser: PropTypes.func
};

export default UserList;
