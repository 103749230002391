import { createContext, useEffect, useRef, useContext, useMemo, useState, useCallback } from "react";
import { MESSAGES_PER_PAGE } from "config";
import { defaultSocket } from 'socket/socketHelper';
import { NewChat, UpdateStatus, NewMessage } from './socketModels';
import { info } from "sass";
 
export const SocketContext = createContext();
export function SocketContextProvider({ children }) {
  // const [isConnected, setIsConnected] = useState();
  const [userInfo, setUserInfo] = useState({});
  // Lista de chats disponibles.
  const [chats, setChats] = useState([]);
  // Mensajes que se estan mostrando en el currentChat
  const [messages, setMessages] = useState([]);
  // Chat que esta abierto.
  const [currentChat, setCurrentChat] = useState({ chatId: ''});
  const [hasMoreMessages, setHasMoreMessages] = useState(true);
  // const [hasMoreChats, setHasMoreChats] = useState(true);
  const [pagination, setPagination] = useState({page: 1, hasMoreChats: false});
  // No usar useRef con esta variable: 'isConnectedToChat' porque causa un ciclo infitio de conectar y desconectar.
  let isConnectedToChat = useRef(false);

  let socket = defaultSocket;
  // Utilerias.
  // Este y el metodo 'isTheSameChat' podrian ser el mismo...pero se ve mas bonito asi al momento de usarlo.
  const isFromSameChat = (msg1 = {}, msg2 = {}) => (
     msg1.chatId === msg2.chatId &&
     msg1.origin === msg2.origin
  );
  const isTheSameChat = (chat1, chat2) => (
    chat1.chatId === chat2.chatId &&
    chat1.origin === chat2.origin
  );

  const getChat = useCallback(({chatId, origin}, myChats) => {
    if (!myChats) {
      myChats = chats;
    }
    return myChats.find(x => x.chatId === chatId && x.origin === origin);
  }, [chats])

  // Eventos.
  const connectToSocket = useCallback(() => {
    setChats([]);
    setMessages([]);
    socket.disconnect();
    socket.tryConnect();
  }, [socket])

  const markAsRead = useCallback((chatToMark) => {
    socket.markAsRead(chatToMark);
    setChats(allChats => {
      return allChats.map(chat => {
        if (isTheSameChat(chat, chatToMark))
          chat.unreadMessages = 0;
        return NewChat(chat);
      })
    });
  }, [socket, setChats])

  const updateLastMessage = useCallback((chatToUpdate) => {
    const updatedChat = getChat(chatToUpdate);
    if (updatedChat) {
      updatedChat.lastMessage = chatToUpdate.message;// ToDo: Si no es texto, actualizarlo con el valor correspondiente.
      updatedChat.lastMessageDate = chatToUpdate.date;
      setChats(chats.map(chat => 
        NewChat(isTheSameChat(updatedChat, chat)? updatedChat : chat)
      ));
    }
  }, [chats, getChat, setChats])

  // Actualiza el orden de los chats y aumenta el numero de mensajes sin leer.
  const updateChat = useCallback((chatToAdd, numberUnreadMessages) => {
    setChats(myChats => {
      const updatedChat = getChat(chatToAdd, myChats);
      if (updatedChat) {
        updatedChat.unreadMessages += numberUnreadMessages;
        return [
        // El chat a actualizar va primero.
        NewChat(updatedChat),
          // Despues van todos los demas chats.
          ...myChats.filter(chat => !isTheSameChat(chat, updatedChat))
        ];
      }

      return myChats;
    })
    /*const updatedChat = getChat(chatToAdd);
    if (updatedChat) {
      updatedChat.unreadMessages += numberUnreadMessages;
      setChats([
        // El chat a actualizar va primero.
        NewChat(updatedChat),
        // Despues van todos los demas chats.
        ...chats.filter(chat => !isTheSameChat(chat, updatedChat))
      ]);
    }*/
  }, [chats, getChat])

  const disconnectFromChat = useCallback(() => {
    // "desconectarlo" sin deconectarlo en realidad, para no causar re-renders inecesarios.
    // eslint-disable-next-line react-hooks/exhaustive-deps
    isConnectedToChat.current = false;
  }, [isConnectedToChat])

  const connectToChat = useCallback((chatId, origin) => {
    const newChat = getChat({chatId, origin});
    if (newChat !== undefined && (!isTheSameChat(currentChat, newChat) || !isConnectedToChat.current)) {
      if (newChat.unreadMessages > 0)
          markAsRead(newChat);
      socket.loadMessagesOf(newChat);
      setHasMoreMessages(true);
      setCurrentChat(newChat);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    isConnectedToChat.current = true;
  }, [currentChat, setHasMoreMessages, setCurrentChat, getChat, markAsRead, socket, isConnectedToChat])

  const getNotifications = useCallback(() => ({
    unreadMessages: chats.reduce(
      (accumulator, chat) => accumulator + chat.unreadMessages, 0
    )
  }), [chats])

  const isValidChat = useCallback((cchat) => 
    chats.find(x => cchat.chatId == x.chatId && cchat.origin == x.origin),
    [chats]
  )


  const closeCurrentChat = useCallback(() => {
    socket.onCloseChat(currentChat)
  }, [currentChat])

  const sendMessage = useCallback((message) => {
    socket.sendMessageOnChat(currentChat, message)
  }, [socket, currentChat])
  
  const loadMoreMessages = useCallback(() => {
    socket.loadMoreMessages(currentChat, messages.length)
  }, [socket, currentChat, messages])

  const loadMoreChats = useCallback(() => {
    setPagination({...pagination, hasMoreChats: false});
    if (pagination.hasMoreChats)
      socket.loadMoreChats(pagination.page + 1)
  }, [socket, pagination])

  //Eventos del socket.
  const onLoadMoreChats = useCallback((info) => {
    setChats(chats => {
      let result = [...chats];
      for (let i=0; i<info.chats.length; i++) {
        if (chats.find(x => isFromSameChat(info.chats[i], x)))
          continue;
        result.push(NewChat(info.chats[i]));
      }
      return result;
    });
    setPagination({
      page: info.pagination.page,
      hasMoreChats: (info.pagination?.pageSize * info.pagination?.page) < info.pagination?.totalOfRecords
    });
  }, [setChats]);

  const onConnection = useCallback((data) => {
    // Esto ahora trae informacion de la paginacion de chats.
    setChats(data.chats.map(chat => NewChat(chat)));
    setPagination({
      page: 1,
      hasMoreChats: data.pagination?.pageSize < data.pagination?.totalOfRecords
    });
    setUserInfo(data.userInfo);
  }, []);

  const onNewChat = useCallback((chat) => {
    // Actualizar el chat en la lista de chats
    setChats(chats => {
      // Si es un chat invalido lo ignora
      if (!chat.chatId || ! chat.origin)
        return chats;
      // Si ya existe lo actualzia.
      if (chats.find(x => isFromSameChat(chat, x))) {
        return chats.map(x => {
          if (isFromSameChat(chat, x)) {
            UpdateStatus(x, chat.status);
            return {...x};
          }
          return x;
        })
      }
      return [NewChat(chat), ...chats]
    });
    // Actualizar el chat actual
    setCurrentChat(current => {
      if (isFromSameChat(chat, current)) {
        UpdateStatus(current, chat.status);
        return {...current};
      }
      return current
    })
  }, [chats]);

  const newMessage = useCallback((newMessage) => {
    setMessages(messages => {
      updateLastMessage(newMessage);
      if (isConnectedToChat.current && isFromSameChat(messages[0], newMessage)) {
        // este metodo espera un chat, pero solo usa las propiedades de chatId y origin, asi que es lo mismo si le envio un objeto de mensaje.
        markAsRead(newMessage);
        updateChat(newMessage, 0);
        return [...messages, NewMessage(newMessage)];
      } else {
        updateChat(newMessage, 1);
        return messages;
      }
    });
  }, [updateChat, updateLastMessage, markAsRead, setMessages, isConnectedToChat]);
  const onUpdateChat = useCallback((newChat) => {
    setChats(chats => {
      return chats.map(chat => {
        if (isFromSameChat(chat, newChat)) {
          setCurrentChat(current => {
             if (isFromSameChat(newChat, current)) {
                return {...current, contact: newChat.contact};
             }
             return current
          })
          chat.contact = newChat.contact;
        }
        return chat;
      })
    });
  }, [])
  const onUpdateWhatsappStatus = useCallback(newMessageStatus => {
    if (!isTheSameChat(currentChat, newMessageStatus))
      return;

    setMessages(currentMessages => {
      return currentMessages.map(message => {
        if (message.messageId === newMessageStatus.messageId) {
          message.whatsappStatus = newMessageStatus.whatsappStatus;
        }
        return message;
      })
    })
  }, [setMessages] );
  const onLeaveChat = useCallback((chat) => {
    setChats(chats => chats.filter(c => !isFromSameChat(c, chat)));
  }, [])

  const onCloseChat = useCallback((chat) => {
    // Buscar y actualizar el estatus del chat cerrado.
    setChats(allChats => allChats.map(x => {
      if (isFromSameChat(chat, x)) {
        UpdateStatus(x, chat.status);
        return {...x};
      }
      return x;
    }));
    // Actualizar el chat actual
    setCurrentChat(current => {
      if (isFromSameChat(chat, current)) {
        UpdateStatus(current, chat.status);
        return {...current};
      }
      return current
    })
  }, [setCurrentChat, setChats])

  const onLoadMessage = useCallback((newMessages) => {
    // Si se cargaron menos mensajes que los mensajes por pagina, el chat no tiene mas mensajes.
    if (newMessages.length < MESSAGES_PER_PAGE) {
      setHasMoreMessages(false);
    }
    setMessages(newMessages.map(message => NewMessage(message)).reverse());
  }, []); 
  
  const onLoadMoreMessage = useCallback((newMessages) => {
    if (!newMessages || newMessages.length === 0) {
      setHasMoreMessages(false);
    }
    setMessages(oldMessages => [...newMessages.map(message => NewMessage(message)).reverse(), ...oldMessages]);
  }, []);
  //Fin Eventos del socket
  useEffect(() => {
    // Eventos del Sistema:
    // socket.on('connect', () => console.log('Se conecto!!'));
    // socket.on('disconnect',() => console.log('Se desconecto!!'));
    socket.disconnect();
    socket.tryConnect();
    socket.on('onNewChat', onNewChat)
    socket.on('onConnection', onConnection)
    socket.on('onLoadMessage', onLoadMessage)
    socket.on('onLoadMoreChats', onLoadMoreChats)
    socket.on('onLoadMoreMessage', onLoadMoreMessage)
    socket.on('newMessage', newMessage)
    socket.on('onLeaveChat', onLeaveChat)
    socket.on('onCloseChat', onCloseChat)
    socket.on('onUpdateChat', onUpdateChat)
    socket.on('onUpdateWhatsappStatus', onUpdateWhatsappStatus)
    return () => {
      // socket.disconnect();
      socket.off('connect');
      socket.off('disconnect');
      socket.off('onNewChat', onNewChat);
      socket.off('onConnection', onConnection);
      socket.off('onLoadMessage', onLoadMessage);
      socket.off('onLoadMoreMessage', onLoadMoreMessage);
      socket.off('onLoadMoreChats', onLoadMoreChats)
      socket.off('newMessage', newMessage);
      socket.off('onLeaveChat', onLeaveChat)
      socket.off('onCloseChat', onCloseChat)
      socket.off('onUpdateChat', onUpdateChat)
      socket.off('onUpdateWhatsappStatus', onUpdateWhatsappStatus)
    };
  }, []);
  const value = useMemo(
      ()=>({
        // Data
        chats, // Lista de chats disponibles.
        currentChat, // Id del chat que se esta mostrando.
        messages, // Lista de mensajes cargados.
        hasMoreMessages,
        hasMoreChats: pagination.hasMoreChats,
        userInfo,
        currentChat,
        // Utileria
        connectToSocket, // Intenta conectarse al socket de nuevo(util por si cambia el jwt)
        connectToChat, // Actualiza el current chat y carga los mensajes del nuevo chat.
        disconnectFromChat,
        sendMessage, // Enviar un mensaje en el 'currentChat'
        loadMoreMessages,
        loadMoreChats,
        getNotifications,
        closeCurrentChat,
        isValidChat,
        setCurrentChat
      }),
      [
        chats, currentChat, messages, hasMoreMessages, pagination.hasMoreChats, userInfo, setCurrentChat,
        connectToSocket,
        connectToChat,
        sendMessage,
        loadMoreMessages,
        loadMoreChats,
        disconnectFromChat,
        getNotifications,
        closeCurrentChat,
        isValidChat
      ]
  );
  return <SocketContext.Provider value={value}>{children}</SocketContext.Provider>;
}



export function useSocketContext() {
    return useContext(SocketContext);
}
